<template>
  <v-container
    class="px-lg-10 mx-lg-0 background-color: white"
    style="border: 1px solid grey"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="10"
      >
        <v-spacer />
      </v-col>
      <v-col
        class="text-right"
        cols="12"
        sm="6"
        md="2"
      >
        <v-btn
          color="orange"
          dark
          class="mb-2"
          @click="cancelButtonClick"
        >
          KEMBALI
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Customer
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ fullName }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Phone
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataPartner.phone }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Email
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataPartner.email }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Status
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-select
          :value="selectedStatus"
          class="mx-0 my-0 px-0 py-0"
          :items="statusItems"
          item-text="text"
          item-value="value"
          @change="handlePartnerStatus"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Langganan Newsletter
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-checkbox
          v-model="dataPartner.newsletter_subscribe"
          class="mx-0 my-0 px-0 py-0"
          :label="newsLetterSubcriptionStatus"
          @click="handleNewsletterSubscription"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dataPartner: {
        hash: '',
        status: '1',
        status_string: 'Aktif',
        fname: '',
        lname: '',
        phone: '',
        profile_verified: '1',
        email: '',
        email_verified: '1',
        newsletter_subscribe: '1',
      },
      statusItems: [
        { text: 'Aktif', value: 1 },
        { text: 'Non-Aktif', value: 2 },
        { text: 'Blokir', value: 3 },
      ],
    }),

    computed: {
      fullName () {
        return this.dataPartner.fname + ' ' + this.dataPartner.lname
      },
      selectedStatus () {
        return parseInt(this.dataPartner.status)
      },
      newsLetterSubcriptionStatus () {
        let status
        this.dataPartner.newsletter_subscribe ? status = 'Aktif' : status = 'Non-Aktif'
        return status
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this
        const requestBody = new URLSearchParams()
        requestBody.append('prt_hash', id)

        axios.post('/t/partner/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataPartner = res.data.data.detail
          }
        }).catch((e) => {
        })
      },

      handlePartnerStatus (val) {
        const requestBody = {
          prt_hash: this.dataPartner.hash,
          new_status: val,
        }

        axios.post('/t/partner/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      handleNewsletterSubscription () {
        const requestBody = {
          prt_hash: this.dataPartner.hash,
        }

        axios.post('/t/partner/mod/newsletter', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Partner' })
      },
    },
  }
</script>
